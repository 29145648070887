import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects'

import {
    GET_USER_INFO,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_REQUEST,
    GET_USER_INFO_SUCCESS,
    UPDATE_STUDENT_PROFILE,
    UPDATE_STUDENT_PROFILE_ERROR,
    UPDATE_STUDENT_PROFILE_REQUEST,
    UPDATE_STUDENT_PROFILE_SUCCESS
} from '../actions/user-actions'

import userApi from '../api/user-api'

import {
    CHECK_PAYMENTS,
    CHECK_PAYMENTS_ERROR,
    CHECK_PAYMENTS_REQUEST,
    CHECK_PAYMENTS_SUCCESS
} from "../actions/billing-actions";

function* getUserInfo({ payload }) {

    yield put({ type: GET_USER_INFO_REQUEST })

    let response = yield call(userApi.getUserInfo, payload)

    if ( response.status === 200 ) {
        yield put({ type: GET_USER_INFO_SUCCESS, payload: response.data })
    } else if( response.status === 400 ){
        yield put({ type: GET_USER_INFO_ERROR, payload: response.status })
    } else {
        yield put({ type: GET_USER_INFO_ERROR, payload: response.status })
    }
}

function* updateStudentProfile({ payload }){

    yield put({ type: UPDATE_STUDENT_PROFILE_REQUEST })

    const response = yield call(userApi.updateUserProfile, payload)

    if (response.status === 200) {
        yield put({ type: UPDATE_STUDENT_PROFILE_SUCCESS, payload: response.data })
    }
    if(response.status === 400 || response.status > 401){
        yield put({ type: UPDATE_STUDENT_PROFILE_ERROR, payload: response.status } )
    }
}

function* checkPayments({ payload }) {

    yield put({ type: CHECK_PAYMENTS_REQUEST })

    const response = yield call(userApi.checkUserPayments, payload)

    if(response.status === 200) {
        yield put({ type: CHECK_PAYMENTS_SUCCESS, payload: response.data })
    } else {
        yield put({ type: CHECK_PAYMENTS_ERROR })
    }

}

export default function* userSaga() {
    yield takeLatest(GET_USER_INFO, getUserInfo)
    yield takeLatest(UPDATE_STUDENT_PROFILE, updateStudentProfile)
    yield takeLatest(CHECK_PAYMENTS, checkPayments)
}
