import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from "../../context/AuthContext";
import {toast} from "react-toastify";
import ToastMessage from "../toast";
import Loading from "../Loading";

function VerifyEmail() {

    const { currentUser, doSendEmailVerification, timeActive, setTimeActive } = useAuth()
    const [time, setTime] = useState(60)
    const navigate = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
            currentUser?.reload()
                .then(() => {
                    if(currentUser?.emailVerified){
                        clearInterval(interval)
                        navigate('/')
                    }
                })
                .catch((error) => {
                    toast.error(<ToastMessage text={error.message} withSupportButton={true} />)
                })
        }, 1000)
    }, [navigate, currentUser])

    useEffect(() => {
        let interval = null
        if(timeActive && time !== 0 ){
            interval = setInterval(() => {
                setTime((time) => time - 1)
            }, 1000)
        }else if(time === 0){
            setTimeActive(false)
            setTime(60)
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [timeActive, time, setTimeActive])

    const resendEmailVerification = async() => {
        await doSendEmailVerification()
    }

    if(!currentUser){
        return <Loading />
    }

    return (
        <div className="flex flex-col items-center min-h-screen bg-brandYellow">
            <div className={"w-full xs:max-w-laptop"}>
                <div className="flex flex-col items-center pt-10 px-10">
                    <div className="w-full bg-base-100 shadow-brand-button">
                        <div className={"flex flex-col items-center justify-center m-5 text-center"}>
                            <h1 className={"prose font-semibold"}>Verify your Email Address</h1>
                            <div className={"flex flex-col items-center justify-center  my-5"}>
                                <span>A Verification email has been sent to:</span>
                                <span>{currentUser?.email}</span>
                            </div>
                            <span className={"text-center"}>Follow the instruction in the email to verify your account</span>
                            <button
                                className={`mt-5 py-2 px-4 bg-brandGray btn btn-md rounded-full btn-wide shadow-brand-button`}
                                onClick={resendEmailVerification}
                                disabled={timeActive}
                            >
                                <span className="font-medium">Resend Email {timeActive && time}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail
