import React, {Component} from "react";
import Smile from '../assets/emoji/grinning-face_1f600.png'
class Holiday extends Component {

    render() {
        return (
            <div className="flex flex-col items-center min-h-screen bg-brandYellow justify-center ">
                <div className={"w-full xs:max-w-laptop"}>
                    <div className="flex flex-col items-center text-center">
                        <h1 className="mb-5 text-3xl font-bold">
                            English in Stories is on Christmas break until January 9
                        </h1>
                        <p className="text-xl">
                            Merry Christmas and Happy New Year.
                        </p>
                        <p className="text-xl">
                            See you soon
                        </p>
                        <img src={Smile} className={'h-16 w-16 mt-10'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Holiday
