import React, {useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "./Loading";
import {Navigate, useNavigate} from "react-router-dom";
import {LOGOUT_REQUESTED} from "../redux/actions/auth-actions";
import {useAuth} from "../context/AuthContext";

const Logout = ({ userInfo }) => {

    const {
        doLogout,
        currentUser
    } = useAuth();

    const navigate = useNavigate();

    useEffect( () => {
        async function fetch() {
            await doLogout();
        }
        fetch()
    }, [])

    useEffect(()=> {
        if(!userInfo && !currentUser){
            navigate('/')
        }
    }, [userInfo, currentUser])

    return <Loading />
}

Logout.propTypes = {
    isLoggedIn: PropTypes.bool,
    logOut: PropTypes.func,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    userInfo: state.user.userInfo
})

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: LOGOUT_REQUESTED }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
