export const returnUrl = process.env.REACT_APP_BASE_URL+'/account'
export const testHomeworkId = process.env.REACT_APP_TEST_HOMEWORK
export const paymentApiUrl = process.env.REACT_APP_PAYMENT_URL+'/api/v1'
export const homeworkApiUrl = process.env.REACT_APP_HOMEWORK_API_URL
export const accountApiUrl = process.env.REACT_APP_ACCOUNT_API_URL
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLICK_KEY
export const priceItems = {
    'aud': 20,
    'usd': 15,
    'eur': 13,
    'rub': 1000,
    'rubDiscount': 400,
    'audDiscount': 8,
    'usdDiscount': 6,
    'eurDiscount': 5,
    'rubSymbol': '₽',
    'eurSymbol': '€',
    'audSymbol': 'A$',
    'usdSymbol': '$'
}
export const billingPaths = [
    'account',
    'subscription',
    'billing',
]
