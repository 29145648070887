import {
    SHOW_MODAL,
    HIDE_MODAL,
    SET_PAGE_BACKGROUND, RESET_PAGE_BACKGROUND,
} from "../actions/common-actions";
import moment from "moment-timezone";

const initialState = {
    modal: false,
    modalType: null,
    pageBackground: {background: '#fff7ef'},
    today: moment().tz('Asia/Shanghai'),
    currentMonday: moment().tz('Asia/Shanghai').isoWeekday(1),
    currentTuesday: moment().tz('Asia/Shanghai').isoWeekday(2).set('hour', 10).set('minute', 30),
    currentSunday: moment().tz('Asia/Shanghai').add(1, 'weeks').isoWeekday(0),
    nextTuesday: moment().tz('Asia/Shanghai').isoWeekday(2).add(1, 'weeks').set('hour', 10).set('minute', 30),
    isWeekEnd: moment().tz('Asia/Shanghai').isoWeekday() === 6 || moment().tz('Asia/Shanghai').isoWeekday() === 7,
    weekNumber: moment().tz('Asia/Shanghai').format('YYYY-[W]WW')
    //weekNumber: moment().tz('Australia/Sydney').isoWeek()
}

export default function CommonReducer(state = initialState, {type, payload}) {
    switch(type) {
        case SHOW_MODAL:
            return {
                ...state,
                modal: true,
                modalType: payload
            };
        case HIDE_MODAL:
            return {
                ...state,
                modal: false,
                modalType: null
            };

        case SET_PAGE_BACKGROUND:
            return {
                ...state,
                pageBackground: payload,
            };

        case RESET_PAGE_BACKGROUND:
            return {
                ...state,
                pageBackground: initialState.pageBackground
            }

        default:
            return state
    }
}
