export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";

export const CLEAN_LOGIN_DATA = "CLEAN_LOGIN_DATA";

export const REGISTRATION = "REGISTRATION";
export const REGISTRATION_REQUEST = "REGISTRATION_REQUESTED";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";
export const CLEAN_REGISTRATION_DATA = "CLEAN_REGISTRATION_DATA"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"
export const CLEAN_RESET_PASSWORD_DATA = "CLEAN_RESET_PASSWORD_DATA"

export const SEND_RESET_LINK = "SEND_RESET_LINK"
export const SEND_RESET_LINK_REQUEST = "SEND_RESET_LINK_REQUEST"
export const SEND_RESET_LINK_SUCCESS = "SEND_RESET_LINK_SUCCESS"
export const SEND_RESET_LINK_ERROR = "SEND_RESET_LINK_ERROR"
export const CLEAN_SEND_LINK_DATA = "CLEAN_SEND_LINK_DATA"

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_REQUESTED = "REFRESH_TOKEN_REQUESTED";
