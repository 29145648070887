import {
    LOCK_SPRINT,
    UNLOCK_SPRINT,
    CHECK_PERMISSIONS_REQUEST,
    CHECK_PERMISSIONS_SUCCESS,
    CHECK_PERMISSIONS_ERROR,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_ERROR,
    SET_USER_INFO,
    UPDATE_STUDENT_PROFILE_REQUEST,
    UPDATE_STUDENT_PROFILE_SUCCESS,
    UPDATE_STUDENT_PROFILE_ERROR,
    RESET_USER_DATA, SET_LOCK_SPRINT
} from "../actions/user-actions";
import { toast } from "react-toastify";
import React from "react";

import ToastMessage from "../../components/toast";

const initialState = {
    lock: {status: true, description: 'init'},
    checking: false,
    checked: false,
    userInfo: null,
    userInfoError: null,
    updateProfileError: null,
    updateProfileLoading: false
};

export default function userReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case CHECK_PERMISSIONS_REQUEST:
            return {
                ...state,
                checking: true
            }

        case CHECK_PERMISSIONS_SUCCESS:
            return {
                ...state,
                checking: false,
                checked: true
            }

        case CHECK_PERMISSIONS_ERROR:
            toast.error(<ToastMessage text={"Couldn't get your permissions."} withSupportButton={true} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                checking: false,
                checked: false
            }

        case UNLOCK_SPRINT:
            return {
                ...state,
                lock: false
            }

        case LOCK_SPRINT:
            return {
                ...state,
                lock: true
            }

        case SET_LOCK_SPRINT:
            return {
                ...state,
                lock: payload
            }

        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: payload,
            }

        case GET_USER_INFO_ERROR:
            toast.error(<ToastMessage text={"Couldn't get your profile info."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                userInfo: null,
                userInfoError: true
            }

        case SET_USER_INFO:
            return {
                ...state,
                userInfo: payload
            }

        /* SET USER PROFILE */
        case UPDATE_STUDENT_PROFILE_REQUEST:
            return {
                ...state,
                updateProfileLoading: true,
            };

        case UPDATE_STUDENT_PROFILE_SUCCESS:
            toast.success(<ToastMessage text={'Your profile has been successfully updated'} />)
            return {
                ...state,
                userInfo: { ...state.userInfo, profile: payload },
                updateProfileLoading: false,
                updateProfileError: null,
            };

        case UPDATE_STUDENT_PROFILE_ERROR:
            toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileError: true,
            };

        case RESET_USER_DATA:
            return {
                ...state,
                lock: initialState.lock,
                checking: false,
                checked: false,
                userInfo: null,
                userInfoError: null,
                updateProfileError: null,
                updateProfileLoading: false
            }

        default:
            return state;
    }
}
