import client from './client'
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import {accountApiUrl, paymentApiUrl} from "../../helpers/constants";

const paymentClient = client(`${paymentApiUrl}`)
const accountClient = client(`${accountApiUrl}`)

export const createStripeCheckoutSession = async (params) => {
    try {
        let data = snakecaseKeys(params)
        const response = await paymentClient.post(`/stripe/checkout/create/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const createYookassaCheckoutSession = async (params) => {
    try {
        let data = snakecaseKeys(params)
        const response = await paymentClient.post(`/yookassa/checkout/create/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const createSetupIntent = async (customer_id) => {
    try {
        let data = snakecaseKeys(customer_id)
        const response = await paymentClient.post(`/stripe/setupintent/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const createStripeSubscription = async (params) => {
    try {
        let data = snakecaseKeys(params)
        const response = await paymentClient.post(`/stripe/subscription/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const updateStripeSubscription = async (params) => {
    try {
        let data = snakecaseKeys(params)
        const response = await paymentClient.put(`/stripe/subscription/${params.subscriptionId}/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const deleteStripeSubscription = async (params) => {
    try {
        let data = snakecaseKeys(params)
        const response = await paymentClient.post(`/stripe/subscription/${params.subscriptionId}/cancel/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const createStripeSubscriptionSchedule = async (params) => {
    try {
        let data = snakecaseKeys(params)
        const response = await paymentClient.post(`/stripe/subscription-schedule/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const deleteStripeSubscriptionSchedule = async (params) => {
    try {
        let data = snakecaseKeys(params)
        const response = await paymentClient.post(`/stripe/subscription-schedule/${params.subscriptionId}/cancel/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const getPaymentMethod = async (paymentMethodId) => {
    try {
        const response = await paymentClient.get(`/stripe/payment-method/${paymentMethodId}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const detachPaymentMethod = async (paymentMethodId) => {
    try {
        const response = await paymentClient.delete(`/stripe/payment-method/${paymentMethodId}/detach/`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const attachPaymentMethod = async (params) => {
    try {
        let data = snakecaseKeys(params)
        const response = await paymentClient.post(`/stripe/payment-method/${params.paymentMethodId}/attach/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const updateSubscriptionDefaultPaymentMethod = async (params) => {

    try {
        let data = snakecaseKeys(params)
        const response = await paymentClient.put(`/stripe/payment-method/${params.paymentMethodId}/subscription/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const paymentApi = {
    createStripeCheckoutSession,
    createSetupIntent,
    createStripeSubscription,
    updateStripeSubscription,
    deleteStripeSubscription,
    createStripeSubscriptionSchedule,
    deleteStripeSubscriptionSchedule,
    createYookassaCheckoutSession,
    getPaymentMethod,
    detachPaymentMethod,
    attachPaymentMethod,
    updateSubscriptionDefaultPaymentMethod
};

export default paymentApi;

