import React, {
    useEffect,
    useState
} from "react";
import {connect} from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Main from "./components/Main";
import AuthProvider from "./context/AuthContext";
import {toast} from "react-toastify";
import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener.js'

const App = () => {

    const [updateWaiting, setUpdateWaiting] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [swListener, setSwListener] = useState({});

    useEffect(() => {
        async function updater(){
            if(process.env.NODE_ENV !== "development"){
                const listner = await new ServiceWorkerUpdateListener()
                setSwListener(listner)

                listner.onupdateinstalling = (installingEvent) => {
                };
                listner.onupdatewaiting = (waitingEvent) => {
                    setUpdateWaiting(true);
                };
                listner.onupdateready = () => {
                    window.location.reload();
                };
                navigator.serviceWorker.getRegistration().then((reg) => {
                    listner.addRegistration(reg);
                    setRegistration(reg);
                });

                return () => listner.removeEventListener();
            }

        }
        updater()

    }, [])

    useEffect(()=>{
        if(updateWaiting){
            toast.info(<UpdateWaiting />, {
                autoClose: false,
                toastId: 'update'
            })
        } else {
            toast.dismiss('update')
        }

    }, [updateWaiting])


    const UpdateWaiting = () => {

        const handleUpdateButton = (event) => {
            event.preventDefault()
            swListener.skipWaiting(registration.waiting)
        }

        return (
            <div className={"flex flex-row justify-between items-center w-full xs:max-w-laptop"}>
                <div>Update waiting!</div>
                <button className={"btn btn-md bg-brandGray ml-2"} onClick={handleUpdateButton}>Update</button>
            </div>
        )
    }

    return (
        <Router>
            <AuthProvider>
                <Main />
            </AuthProvider>
        </Router>
    )
}

App.propTypes = {
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(App)
