import {
    put,
    call,
    all,
    takeLatest,
    takeEvery
} from 'redux-saga/effects'

import {
    SET_LOADING,
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
    SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED,
    SET_HOMEWORK_ID,
    SET_HOMEWORK_ID_REQUESTED,
    CREATE_STUDENT_ANSWERS,
    GET_FTF_PAIR_BY_DATES_REQUESTED,
    SET_FTF,
    SET_FTF_PAIR,
    CREATE_STUDENT_ANSWERS_REQUEST,
    CREATE_STUDENT_ANSWERS_SUCCESS,
    CREATE_STUDENT_ANSWERS_ERROR,
    GET_HOMEWORK,
    GET_STUDENT_ANSWERS_REQUEST,
    GET_STUDENT_ANSWERS_SUCCESS,
    GET_HOMEWORK_CONFIG_REQUEST,
    GET_HOMEWORK_CONFIG_SUCCESS,
    GET_HOMEWORK_CONFIG_ERROR,
    GET_STUDENT_ANSWERS_ERROR,
    GET_DAILY_SUMMARY,
    GET_DAILY_SUMMARY_SUCCESS,
    GET_DAILY_SUMMARY_ERROR,
    GET_DAILY_SUMMARY_REQUEST,
    GET_SPRINT_REQUEST,
    GET_SPRINT_SUCCESS,
    GET_SPRINT_ERROR,
    GET_SPRINT,
    GET_SPRINT_SUMMARY,
    GET_SPRINT_SUMMARY_REQUEST, GET_SPRINT_SUMMARY_SUCCESS, GET_SPRINT_SUMMARY_ERROR
} from '../actions/configurator-actions'

import confApi from '../api/configurator-api'

function* getHomework({ payload }){

    // Проверяем есть ли у студента ответы
    yield put({ type: GET_STUDENT_ANSWERS_REQUEST })

    let response = yield call(confApi.getStudentAnswerByHomeworkId, payload)

    if (response.status === 200) {

        yield put({ type: GET_HOMEWORK_CONFIG_SUCCESS, payload: response.data.configuration })

        if(response.data.result) {
            yield put({ type: GET_STUDENT_ANSWERS_SUCCESS, payload: response.data.result })
        }
    }
    else {
        yield put({ type: GET_STUDENT_ANSWERS_ERROR })
    }
}

function* setHomeworkId({ payload }) {
    yield put({ type: SET_HOMEWORK_ID, payload: payload })
}

function* createStudentAnswer({ payload }) {

    yield put({ type: CREATE_STUDENT_ANSWERS_REQUEST })

    const response = yield call(confApi.createAnswer, payload)

    if(response && response.status === 200) {
        yield put({type: CREATE_STUDENT_ANSWERS_SUCCESS, payload: response.data })
    } else {
        yield put({ type: CREATE_STUDENT_ANSWERS_ERROR })
    }
}

function* getDailySummary({ payload }) {

    yield put({ type: GET_DAILY_SUMMARY_REQUEST })

    const response = yield call(confApi.getHomeworksByDate, payload.startDate)

    if(response && response.status === 200) {

        const calls = yield [];
        const configs = response.data

        if(configs.length > 0) {

            yield configs.forEach((config) => {
                calls.push(call(confApi.getStudentAnswerByHomeworkId, {
                    homeworkId:config.homework.id,
                    studentUid: payload.studentUid
                }));
            });

            let result = yield all(calls);

            result = result.map((r, index) => {
                let data = r.data

                if(data.answer?.length > 0) {
                    data.homework = configs[index].homework
                    data.homework.type = configs[index].type
                    data.homework.media = configs[index].media.type
                } else {
                    data = {
                            homework: configs[index].homework,
                            allAssignments: 0,
                            correctAnswer: 0
                    }
                    data.homework.type = configs[index].type
                    data.homework.media = configs[index].media.type
                }
                return [data]
            })
            yield put({ type: GET_DAILY_SUMMARY_SUCCESS, payload: result} )

        } else {
            yield put({ type: GET_DAILY_SUMMARY_SUCCESS, payload: []} )
        }
    } else {
        yield put({ type: GET_DAILY_SUMMARY_ERROR })
    }
}

function* getSprintSummary({ payload }) {

    yield put({ type: GET_SPRINT_SUMMARY_REQUEST })

    const response = yield call(confApi.getSprintSummary, payload)

    if(response && response.status === 200) {
        yield put({ type: GET_SPRINT_SUMMARY_SUCCESS, payload: response.data} )
    } else {
        yield put({ type: GET_SPRINT_SUMMARY_ERROR, payload: response.status })
    }
}

function* setHomeworkConfigAssignment({ payload }) {
    yield put({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: payload })
}

function* getSprint({ payload }) {

    yield put({ type: GET_SPRINT_REQUEST })

    const response = yield call(confApi.getSprint, payload)

    if(response && response.status === 200) {
        if(response.data.length === 1){
            yield put({type: GET_SPRINT_SUCCESS, payload: response.data[0] })
        } else if(response.data.length === 0) {
            yield put({type: GET_SPRINT_SUCCESS, payload: null })
        } else {
            yield put({ type: GET_SPRINT_ERROR, payload: {
                text:' Get more then one sprint.'
            }})
        }
    } else {
        yield put({ type: GET_SPRINT_ERROR, payload: {
                text:'Can not get sprint info.',
                code: response.status
            }})
    }
}

function* getFtfPairByDateRange({ payload }){

    yield put({ type: SET_LOADING, payload: true })

    const ftfResponse = yield call(confApi.getFtfByDates, payload)

    if (ftfResponse.status === 200) {
        let ftf = ftfResponse.data[0]
        if(ftf === undefined) {
            yield put({ type: SET_FTF, payload: {} })
        } else {
            yield put({ type: SET_FTF, payload: ftf })

            const params = {
                sprint: ftf.sprint.id,
                student: payload.instagram
            }

            const pairResponse = yield call(confApi.getFtfPairByFtfAndStudent, params)

            if (pairResponse.status === 200) {
                yield put({ type: SET_FTF_PAIR, payload: pairResponse.data[0] })
            }
            if(pairResponse.status >=400 && pairResponse.status < 600){
                // yield put({ type: SET_SPRINT_STUDENTS, payload: [] })
            }
        }
    }
    if(ftfResponse.status >=400 && ftfResponse.status < 600){
        // yield put({ type: SET_SPRINT_STUDENTS, payload: [] })
    }

    yield put({ type: SET_LOADING, payload: false })
}

export default function* configSaga() {
    yield takeLatest(GET_HOMEWORK, getHomework)
    yield takeLatest(GET_SPRINT, getSprint)
    yield takeLatest(CREATE_STUDENT_ANSWERS, createStudentAnswer)
    yield takeEvery(GET_DAILY_SUMMARY, getDailySummary)
    yield takeLatest(GET_SPRINT_SUMMARY, getSprintSummary)
    yield takeLatest(SET_HOMEWORK_ID_REQUESTED, setHomeworkId)
    yield takeLatest(SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED, setHomeworkConfigAssignment)
    yield takeLatest(GET_FTF_PAIR_BY_DATES_REQUESTED, getFtfPairByDateRange)
}
