import {
    SET_LOADING,
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
    CLEAR_HOMEWORK_CONFIG_FORM,
    SET_HOMEWORK_ID,
    SET_STUDENT_ANSWERS,
    SET_EMPTY_STUDENT_ANSWERS,
    RESET_DAILY_SUMMARY,
    SET_FTF,
    SET_FTF_PAIR,
    SET_EMPTY_FTF,
    SET_EMPTY_HOMEWORK_ID,
    SET_EMPTY_ALL,
    CREATE_STUDENT_ANSWERS_REQUEST,
    CREATE_STUDENT_ANSWERS_SUCCESS,
    CREATE_STUDENT_ANSWERS_ERROR,
    GET_STUDENT_ANSWERS_SUCCESS,
    GET_STUDENT_ANSWERS_REQUEST,
    GET_STUDENT_ANSWERS_ERROR,
    GET_HOMEWORK_CONFIG_SUCCESS,
    GET_HOMEWORK_CONFIG_ERROR,
    GET_HOMEWORK_CONFIG_REQUEST,
    GET_DAILY_SUMMARY_REQUEST,
    GET_DAILY_SUMMARY_SUCCESS,
    GET_DAILY_SUMMARY_ERROR,
    CLEAN_HOMEWORK_DATA,
    GET_SPRINT_REQUEST,
    GET_SPRINT_SUCCESS,
    GET_SPRINT_ERROR, GET_SPRINT_SUMMARY_REQUEST, GET_SPRINT_SUMMARY_SUCCESS, GET_SPRINT_SUMMARY_ERROR,
} from '../actions/configurator-actions'

import { toast } from "react-toastify";
import ToastMessage from "../../components/toast";

const initialState = {
    loadingGetHomework: false,
    currentSprint: null,
    currentSprintLoading: false,
    sprintSummary: null,
    sprintSummaryLoading: false,
    getHomeworkError: null,
    loadingCreateStudentAnswer: false,
    createStudentAnswerError: null,
    loadingDailySummary: false,
    dailySummaryError: null,
    hasAnswers: false,
    loading: false,
    homeworkId: null,
    type: '',
    videoLink: '',
    startTime: 0.0,
    endTime: 0.0,
    withMedia: false,
    image: null,
    isImage: false,
    isVideo: false,
    background: { type: '', source: '' },
    assignment: [],
    editorRawData: { blocks: [], entityMap: {} },
    studentAnswers: null,
    dailySummary: null,
    ftf: {},
    pair: {}
}

export default function ConfiguratorReducer(state = initialState, {type, payload}) {
    let isVideo;
    let isImage;

    switch(type) {

        case SET_LOADING:
            return {
                ...state,
                loading: payload !== true ? payload : true
            }

        case GET_STUDENT_ANSWERS_REQUEST:
            return {
                ...state,
                loadingGetHomework: false,
                getHomeworkError: null,
            }

        case GET_STUDENT_ANSWERS_SUCCESS:
            return {
                ...state,
                studentAnswers: payload,
                loadingGetHomework: false,
                getHomeworkError: null,
                hasAnswers: true
            }

        case GET_STUDENT_ANSWERS_ERROR:
            return {
                ...state,
                loadingGetHomework: false,
                getHomeworkError: {
                    title: 'Can`t get homework',
                    message: 'Try again later or',
                    params: {withSupportButton: true}
                },
                hasAnswers: false
            }

        case GET_HOMEWORK_CONFIG_REQUEST:
            return {
                ...state,
                loadingGetHomework: true,
                getHomeworkError: null,
            }

        case GET_HOMEWORK_CONFIG_SUCCESS:
            isVideo = false;
            isImage = false;

            if(payload.media !== null && payload.media.type === 'video') {
                isVideo = true
            }
            if(payload.media !== null && payload.media.type === 'image') {
                isImage = true
            }

            return {
                ...state,
                homeworkId: payload.homework.id,
                type: payload.type,
                videoLink: isVideo ? payload.media.videoLink : initialState.videoLink,
                startTime: isVideo ? payload.media.startTimeMin * 60 + payload.media.startTimeSec : initialState.startTime,
                endTime: isVideo ? payload.media.endTimeMin * 60 + payload.media.endTimeSec : initialState.endTime,
                image: isImage ? payload.media.image : initialState.image,
                background: payload.background,
                assignment: payload.assignment,
                editorRawData: payload.editorRawData,
                loading: false,
                withMedia: isImage || isVideo,
                isImage: isImage,
                isVideo: isVideo,
                loadingGetHomework: false,
                getHomeworkError: null,
            }

        case GET_HOMEWORK_CONFIG_ERROR:
            return {
                ...state,
                type: initialState.type,
                videoLink: initialState.videoLink,
                startTime: initialState.startTime,
                endTime: initialState.endTime,
                background: initialState.background,
                assignment: initialState.assignment,
                editorRawData: initialState.editorRawData,
                loadingGetHomework: false,
                getHomeworkError: 'Can`t get homework. Try again later or contact with support',
                hasAnswers: false
            }

        case CLEAN_HOMEWORK_DATA:
            return {
                ...state,
                type: initialState.type,
                videoLink: initialState.videoLink,
                startTime: initialState.startTime,
                endTime: initialState.endTime,
                background: initialState.background,
                assignment: initialState.assignment,
                editorRawData: initialState.editorRawData,
                loadingGetHomework: false,
                getHomeworkError: null,
                hasAnswers: false,
                studentAnswers: null,
            }

        case SET_STUDENT_ANSWERS:
            return {
                ...state,
                studentAnswers: payload,
                loading: false
            }

        case CREATE_STUDENT_ANSWERS_REQUEST:
            return {
                ...state,
                loadingCreateStudentAnswer: true,
                createStudentAnswerError: null,
                hasAnswers: false
            }

        case CREATE_STUDENT_ANSWERS_SUCCESS:
            return {
                ...state,
                studentAnswers: payload,
                loadingCreateStudentAnswer: false,
                createStudentAnswerError: null,
                hasAnswers: true
            }

        case CREATE_STUDENT_ANSWERS_ERROR:
            return {
                ...state,
                loadingCreateStudentAnswer: false,
                createStudentAnswerError: "Can`t save your answer. Try again later or contact with support",
                hasAnswers: false
            }

        case SET_EMPTY_STUDENT_ANSWERS:
            return {
                ...state,
                studentAnswers: initialState.studentAnswers,
                loading: false
            }

        case SET_HOMEWORK_ID:
            return {
                ...state,
                homeworkId: payload
            }

        case SET_EMPTY_HOMEWORK_ID:
            return {
                ...state,
                homeworkId: initialState.homeworkId
            }

        case SET_HOMEWORK_CONFIG_ASSIGNMENT:
            return {
                ...state,
                assignment: payload
            }

        case CLEAR_HOMEWORK_CONFIG_FORM:
            return {
                ...state,
                type: '',
                background: {type: '', source: ''},
                videoLink: '',
                assignment: []
            }

        case RESET_DAILY_SUMMARY:
            return {
                ...state,
                dailySummary: initialState.dailySummary
            }

        case GET_DAILY_SUMMARY_REQUEST:
            return {
                ...state,
                loadingDailySummary: true,
                dailySummaryError: null
            }

        case GET_DAILY_SUMMARY_SUCCESS:
            return {
                ...state,
                loadingDailySummary: false,
                dailySummaryError: null,
                dailySummary: payload
            }

        case GET_DAILY_SUMMARY_ERROR:
            return {
                loadingDailySummary: false,
                dailySummaryError: 'Oops',
            }

        case GET_SPRINT_SUMMARY_REQUEST:
            return {
                ...state,
                loadingSprintSummary: true,
            }

        case GET_SPRINT_SUMMARY_SUCCESS:
            return {
                ...state,
                loadingSprintSummary: false,
                sprintSummary: payload
            }

        case GET_SPRINT_SUMMARY_ERROR:
            toast.error(<ToastMessage text={"Can not get sprint summary"} code={payload} />)
            return {
                loadingSprintSummary: false,
            }

        case SET_FTF:
            return {
                ...state,
                ftf: payload
            }

        case SET_FTF_PAIR:
            return {
                ...state,
                pair: payload
            }

        case SET_EMPTY_FTF:
            return {
                ...state,
                ftf: initialState.ftf
            }

        case SET_EMPTY_ALL:
            return {
                ...state,
                loading: initialState.loading,
                homeworkId: initialState.homeworkId,
                type: initialState.type,
                videoLink: initialState.videoLink,
                startTime: initialState.startTime,
                endTime: initialState.endTime,
                withMedia: initialState.withMedia,
                image: initialState.image,
                isImage: initialState.isImage,
                isVideo: initialState.isVideo,
                background: initialState.background,
                assignment: initialState.assignment,
                editorRawData: initialState.editorRawData,
                studentAnswers: initialState.studentAnswers,
                dailySummary: initialState.dailySummary,
                ftf: initialState.ftf,
                pair: initialState.pair
            }

        case GET_SPRINT_REQUEST:
            return {
                ...state,
                currentSprintLoading: true
            }

        case GET_SPRINT_SUCCESS:
            return {
                ...state,
                currentSprint: payload,
                currentSprintLoading: false
            }

        case GET_SPRINT_ERROR:
            toast.error(<ToastMessage text={payload.text} code={payload.code} />)
            return {
                ...state,
                currentSprint: payload,
                currentSprintLoading: false
            }

        default:
            return state
    }
}
