import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects'

import {
    GET_OPTIONS,
    GET_OPTIONS_ERROR,
    GET_OPTIONS_REQUEST,
    GET_OPTIONS_SUCCESS,
} from "../actions/preference-actions";

import preferenceApi from "../api/preference-api";

function* getOptions() {

    yield put({ type: GET_OPTIONS_REQUEST })

    try {
        const response = yield call(preferenceApi.getOptions)

        if(response.status === 200) {
            yield put({type: GET_OPTIONS_SUCCESS, payload: response.data })
        } else if(response.status === 404) {
            yield put({type: GET_OPTIONS_SUCCESS, payload: [] })
        } else {
            yield put({ type: GET_OPTIONS_ERROR })
        }
    } catch (error) {
        yield put({ type: GET_OPTIONS_ERROR })
    }

}

export default function* preferenceSaga() {
    yield takeLatest(GET_OPTIONS, getOptions)
}
