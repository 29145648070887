import React, {useEffect, useState} from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {BsPeople} from "react-icons/bs";
import {FiLogOut} from "react-icons/fi";
import {MdTaskAlt} from "react-icons/md"
import {RiAccountCircleLine} from "react-icons/ri"
import {BsCreditCard2Back} from "react-icons/bs"
import {Link, useLocation} from "react-router-dom";
import {useAuth} from "../context/AuthContext";
import {billingPaths} from "../helpers/constants";


const Menu = ({ lock, userInfo }) => {

    const location = useLocation();
    const { currentUser } = useAuth();
    const pathname = location.pathname || '/'

    const [activeItem, setActiveItem] = useState(pathname)
    const [canPaid, setCanPaid] = useState(false)
    const isBillingPath = billingPaths.includes(pathname.slice(1))

    useEffect(()=>{
        if(userInfo){
            setCanPaid(userInfo.profile.instagram && userInfo.profile.email && userInfo.profile.currency)
        }
    }, [userInfo])

    useEffect(()=>{
        if(lock.status === true && !isBillingPath){
            setActiveItem('/account')
        } else {
            switch (pathname) {
                case '/':
                    setActiveItem('/sprint')
                    break;
                // TODO: Вернуть после интеграции с оплатами
                // case '/billing':
                //     setActiveItem('/billing')
                //     break;
                // case '/talks':
                //     setActiveItem('/talks')
                //     break;
                // case '/end':
                //     setActiveItem('/talks')
                //     break;
                case '/account':
                    setActiveItem('/account')
                    break;
                case '/payment':
                    setActiveItem('/account')
                    break;
                default:
                    setActiveItem('/sprint')
            }
        }
    }, [lock, pathname])

    return (
        <React.Fragment>
            {currentUser && userInfo && pathname !== '/verify-email' && !pathname.startsWith('/homework') ? (
                <footer className="w-full sticky flex flex-row justify-center items-center left-0 right-0 bottom-5 px-10">

                    <ul className="w-full menu menu-horizontal bg-base-100 rounded-box shadow-brand-button border border-brandGray w-full xs:max-w-laptop justify-center">
                        <li
                            className={`rounded-none ${lock.status ? 'disabled cursor-not-allowed' : ''} ${activeItem === '/sprint' ? 'bordered' : ''}`}
                            onClick={() => !lock.status && setActiveItem('/sprint')}
                        >
                            <Link className={"rounded-none"} to={'/sprint'} style={{pointerEvents: lock.status ? 'none' : ''}}>
                                <MdTaskAlt className={"h-7 w-7"}/>
                            </Link>
                        </li>
                        {/* TODO: Вернуть после интеграции с оплатами */}
                        {/*<li*/}
                        {/*    className={`rounded-none ${lock.status ? 'disabled cursor-not-allowed' : ''} ${activeItem === '/talks' ? 'bordered' : ''}`}*/}
                        {/*    onClick={() => !lock.status && setActiveItem('/talks')}*/}
                        {/*>*/}
                        {/*    <Link className={"rounded-none"} to={'/talks'} style={{pointerEvents: lock.status ? 'none' : ''}}>*/}
                        {/*        <BsPeople className={"h-7 w-7"} />*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        <li
                            className={`rounded-none ${activeItem === '/account' ? 'bordered' : ''} `}
                            onClick={() => setActiveItem('/account')}>
                            <Link to="/account">
                                <RiAccountCircleLine className={"h-7 w-7"} />
                            </Link>
                        </li>
                        <li
                            onClick={() => setActiveItem('/logout')}
                            className={`rounded-none ${activeItem === '/logout' ? 'bordered' : ''} `}
                        >
                            <Link className={"rounded-none"} to="/logout">
                                <FiLogOut className={"h-7 w-7"} />
                            </Link>
                        </li>
                    </ul>
                </footer>
            ) : null}
        </React.Fragment>
    );
}

Menu.propTypes = {
    lock: PropTypes.object,
    userInfo: PropTypes.object,
    checked: PropTypes.bool
}

const mapStateToProps = (state) => ({
    lock: state.user.lock,
    userInfo: state.user.userInfo,
    checked: state.user.checked
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
