import { toast } from "react-toastify";
import React from "react";
import ToastMessage from "../../components/toast";

import {
    GET_ALL_PAYMENT_METHODS_ERROR,
    GET_ALL_PAYMENT_METHODS_REQUEST,
    GET_ALL_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHOD_REQUEST,
    GET_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHOD_ERROR,
    DETACH_PAYMENT_METHOD_REQUEST,
    DETACH_PAYMENT_METHOD_SUCCESS,
    DETACH_PAYMENT_METHOD_ERROR,
    ATTACH_PAYMENT_METHOD_REQUEST,
    ATTACH_PAYMENT_METHOD_SUCCESS,
    ATTACH_PAYMENT_METHOD_ERROR,
    UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_REQUEST,
    UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_SUCCESS, UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_ERROR
} from "../actions/payment-method-actions";

const initialState = {
    getPaymentMethodLoading: false,
    getAllPaymentMethodsLoading: false,
    attachPaymentMethodLoading: false,
    detachPaymentMethodLoading: false,
    updateDefaultMethodLoading: false,
    paymentMethods: []
};

export default function paymentMethodReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        /* GET ALL PAYMENT METHODS */
        case GET_ALL_PAYMENT_METHODS_REQUEST:
            return {
                ...state,
                getAllPaymentMethodsLoading: true,
            };

        case GET_ALL_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                paymentMethods: payload,
                getAllPaymentMethodsLoading: false,
            };

        case GET_ALL_PAYMENT_METHODS_ERROR:
            toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                getAllPaymentMethodsLoading: false,
            };

        /* GET PAYMENT METHOD BY ID */
        case GET_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                getPaymentMethodLoading: true,
            };

        case GET_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethods: [...state.paymentMethods, payload],
                getPaymentMethodLoading: false,
            };

        case GET_PAYMENT_METHOD_ERROR:
            toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                getPaymentMethodLoading: false,
            };

        /* UPDATE SUBSCRIPTION DEFAULT PAYMENT METHOD */
        case UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                updateDefaultMethodLoading: true,
            };

        case UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                updateDefaultMethodLoading: false
            };

        case UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_ERROR:
            toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                updateDefaultMethodLoading: false,
            };

        /* DETACH PAYMENT METHOD */
        case DETACH_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                detachPaymentMethodLoading: true,
            };

        case DETACH_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethods: state.paymentMethods.filter( method => method.id !== payload ),
                detachPaymentMethodLoading: false,
            };

        case DETACH_PAYMENT_METHOD_ERROR:
            toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                detachPaymentMethodLoading: false,
            };

        /* ATTACH PAYMENT METHOD */
        case ATTACH_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                attachPaymentMethodLoading: true,
            };

        case ATTACH_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethods: [...state.paymentMethods, payload],
                attachPaymentMethodLoading: false,
            };

        case ATTACH_PAYMENT_METHOD_ERROR:
            toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                attachPaymentMethodLoading: false,
            };

        default:
            return state;
    }
}
