import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Divider from '../assets/divider.svg'
import {
  CLEAR_MESSAGE_REQUESTED
} from "../redux/actions/message-actions";
import {BsApple, BsFacebook, BsGoogle} from "react-icons/bs";
import LocalLogin from "./login/LocalLogin";
import LocalRegistration from "./login/LocalRegistration";
import ResetPassword from "./login/ResetPassword";
import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../context/AuthContext";
import {
    facebookProvider,
    googleProvider,
    appleProvider
} from "../firebase";
import Loading from "./Loading";
import {CLEAN_LOGIN_DATA} from "../redux/actions/auth-actions";
import ToastMessage from "./toast";
import {toast} from "react-toastify";

function Login ({
                    message,
                    userInfo,
                    lock,
                    cleanLoginData,
}) {

    const location = useLocation();
    const [from, setFrom] = useState(location.state?.from)
    const [pathname, setPathname] = useState(location.pathname)
    const { doSocialSignIn, currentUser } = useAuth();

    let [tab, setTab] = useState("tab-1");
    let [resetPassword, setResetPassword] = useState(false)
    let [navigateTo, setNavigateTo] = useState('/')

    const signInWithSocialAccount = async (provider) => {
        try {
            window.history.replaceState({}, '', navigateTo);
            await doSocialSignIn(provider);
        } catch (error) {
            toast.error(<ToastMessage text={"error while signing up with provider"} withTryAgain={true} />)
        }
    };

    useEffect(()=>{
        if(location.pathname === undefined) {
            setPathname('/')
        }
        if(location.state?.from === undefined) {
            setFrom('/')
        }
    }, [location])

    useEffect(()=>{
        if(from === '/' && pathname === '/login') {
            setNavigateTo('/')
        } else {
            if(pathname === '/login' && (from !== '/' || from !== '/day')) {
                setNavigateTo(from)
            } else {
                setNavigateTo('/')
            }
        }
        return () => {
            cleanLoginData()
        }
    }, [from, pathname, cleanLoginData])

    useEffect(()=>{
        if(currentUser && userInfo){
        }
    }, [currentUser, userInfo])

    if(currentUser && userInfo && (lock.description !== 'init' && lock.status === true)) {
        return <Navigate to={'/account'} />;
    } else if(currentUser && !userInfo && !lock){
        return <Loading />
    } else if(currentUser && userInfo && (lock.status === false)){
        return <Navigate to={navigateTo} />;
    } else {
        return (
            <React.Fragment>
                <div className="flex flex-col justify-center items-center h-screen">
                    <div className="bg-white text-center px-5 py-3">
                        <h1 className="font-semibold text-2xl inline-block align-middle">CIS </h1>
                        <img
                            alt="Divider"
                            className="inline-block mx-4"
                            src={Divider}
                        />
                        <p className="inline-block text-xl align-middle">Chinese in Stories</p>
                    </div>
                    <div className="flex flex-col w-full xs:max-w-laptop items-center">
                        <div className="tabs px-5 pt-10">
                            <button
                                className={`tab tab-bordered ${tab === 'tab-1' ? 'tab-active': ''}`}
                                onClick={() => {
                                    setResetPassword(false)
                                    setTab('tab-1')
                                }}
                            >
                                Login
                            </button>
                            <button
                                className={`tab tab-bordered ${tab === 'tab-2' ? 'tab-active': ''}`}
                                onClick={() => {
                                    setTab('tab-2')
                                }}
                            >
                                Registration
                            </button>
                        </div>

                        {tab === 'tab-1' && (
                            <div className={ 'w-full pt-4 px-10 flex flex-col items-center' }>
                                { resetPassword ? (
                                    <>
                                        <ResetPassword />
                                        <p
                                            className={"mt-4 underline underline-offset-1 cursor-pointer"}
                                            onClick={() => setResetPassword(false)}
                                        >
                                            Continue with email?
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <LocalLogin />
                                        <p
                                            className={"mt-4 underline underline-offset-1 cursor-pointer"}
                                            onClick={() => setResetPassword(true)}
                                        >
                                            Reset password?
                                        </p>
                                    </>
                                ) }

                            </div>
                        )}

                        {tab === 'tab-2' && (
                            <div className={ 'w-full pt-4 px-10' }>
                                <LocalRegistration />
                            </div>
                        )}

                        <div className="flex flex-col w-full">
                            <h2 className="text-center mt-4 mb-4">Or authorize your account</h2>
                            <div className={"flex flex-row justify-center"}>
                                <div onClick={() => signInWithSocialAccount(googleProvider)} className="avatar placeholder mx-4">
                                    <div className="p-4 bg-white rounded-full shadow-brand">
                                        <BsGoogle className={"w-6 h-6"}/>
                                    </div>
                                </div>
                                <div onClick={() => signInWithSocialAccount(facebookProvider)} className="avatar placeholder mx-4">
                                    <div className="p-4 bg-white rounded-full shadow-brand">
                                        <BsFacebook className={"w-6 h-6"}/>
                                    </div>
                                </div>
                                <div onClick={() => signInWithSocialAccount(appleProvider)} className="avatar placeholder mx-4">
                                    <div className="p-4 bg-white rounded-full shadow-brand">
                                        <BsApple className={"w-6 h-6"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {message && (
                            <div className="alert glass alert-error mx-10 mt-10 text-center">
                                {message}
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }




}

Login.propTypes = {
    checking: PropTypes.bool,
    checked: PropTypes.bool,
    lock: PropTypes.object,
    userInfo: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    message: PropTypes.string,
    clearMessage: PropTypes.func,
    getUserInfo: PropTypes.func,
    loadingLogin: PropTypes.bool,
    loginWithProvider: PropTypes.bool
}

const mapStateToProps = (state) => ({
    checked: state.user.checked,
    checking: state.user.checking,
    lock: state.user.lock,
    userInfo: state.user.userInfo,
    message: state.message.message,
    loadingLogin: state.auth.loadingLogin,

})

const mapDispatchToProps = (dispatch) => ({
    clearMessage: () => dispatch({ type: CLEAR_MESSAGE_REQUESTED }),
    cleanLoginData: () => dispatch({ type: CLEAN_LOGIN_DATA }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
