import React, {createContext, useContext, useEffect, useState} from "react";
import {auth} from "../firebase";

import {
    onAuthStateChanged,
    sendEmailVerification,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithRedirect,
    signOut,
    getRedirectResult
} from "firebase/auth";

import Loading from "../components/Loading";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {LOGOUT_REQUESTED, REFRESH_TOKEN} from "../redux/actions/auth-actions";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import ToastMessage from "../components/toast";
import {GET_OPTIONS, SET_OPTIONS} from "../redux/actions/preference-actions";
import {GET_USER_INFO, RESET_USER_DATA} from "../redux/actions/user-actions";

// create context
const AuthContext = createContext();

// use AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

// AuthContext Provider with values
const AuthProvider = ({ children, setToken, logOut, getOptions, setOptions, getUserInfo, resetUserData}) => {

    let [currentUser, setCurrentUser] = useState(null);
    const [timeActive, setTimeActive] = useState(false)
    let [loading, setLoading] = useState(true);

    const navigate = useNavigate()

    if(currentUser){
        setToken(currentUser.accessToken)
    }

    useEffect(()=>{
        if(currentUser){
            getOptions()
            getUserInfo(currentUser.uid)
        }
        return setOptions(null)
    }, [currentUser, getOptions, setOptions, getUserInfo])

    useEffect(() => {
        // onAuthStateChanged will executed in login and logout
        // unsubscribe when unmounting the component
        return onAuthStateChanged(auth, async (user) => {
            getRedirectResult(auth).catch((error) => {
                switch (error.code) {
                    case 'auth/account-exists-with-different-credential':
                        toast.error(<ToastMessage text={`Account exists with different credential.`} withTryAgain={true} withSupportButton={true}/>,
                            {autoClose: false}
                        )
                        break;
                    default:
                        toast.error(
                            <ToastMessage text={`An error occurred ${error.code}.`} withSupportButton={true} />,
                            {autoClose: false}
                        )
                        break;
                }
            });
            setCurrentUser(user);
            setLoading(false);
        });
    }, []);

    // google sign up with popup
    let doSocialSignIn = (provider) => {
        setLoading(true)
        return signInWithRedirect(auth, provider);
    };

    // this will logout a user
    let doLogout = () => {
        resetUserData()
        return signOut(auth);
    };

    let doSignupWithEmailPass = async (email, password) => {
        try {
            toast.dismiss()
            const response = await createUserWithEmailAndPassword(auth, email, password)
            if(response){
                try {
                    await sendEmailVerification(auth.currentUser)
                    setTimeActive(true)
                    navigate('/verify-email')
                } catch (error) {
                    toast.error(<ToastMessage text={error.message} withSupportButton={true} />)
                    return false
                }
            }
        } catch (error) {
            switch (error.code) {
                case 'auth/invalid-email':
                    toast.error(<ToastMessage text={"Invalid email."} withTryAgain={true} />,
                        {autoClose: false}
                    )
                    break;
                default:
                    toast.error(
                        <ToastMessage text={`An error occurred ${error.code}.`} withSupportButton={true} />,
                        {autoClose: false}
                    )
                    break;
            }
            return false
        }
        return true
    };

    let doSendEmailVerification = () => {
        sendEmailVerification(auth.currentUser)
            .then(() => {
                setTimeActive(true)
            }).catch((error) => {
                toast.error(<ToastMessage text={error.message} withSupportButton={true} />,
                    {autoClose: false}
                )
            })
    }

    // this will login with email and pasword
    let doSigninWithEmailPass = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    // this will reset user password
    let doResetPassword = (email) => {
        return sendPasswordResetEmail(auth, email)
    };

    // this will remove current user
    let doRemoveUser = () => {
        return currentUser.delete();
    };

    // context value object
    const value = {
        currentUser,
        loading,
        setLoading,
        timeActive,
        setTimeActive,
        doSocialSignIn,
        doLogout,
        doSignupWithEmailPass,
        doSendEmailVerification,
        doSigninWithEmailPass,
        doResetPassword,
        doRemoveUser,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading ? children : <Loading />}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    cleanLoginData: PropTypes.func,
    checking: PropTypes.bool,
    setToken: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    loadingLogin: PropTypes.bool,
    loginError: PropTypes.object,
    resetUserData: PropTypes.func
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    checking: state.user.checking,
    loadingLogin: state.auth.loadingLogin,
    loginError: state.auth.loginError
})

const mapDispatchToProps = (dispatch) => ({
    setToken: (token) => dispatch({type: REFRESH_TOKEN, payload: token}),
    logOut: () => dispatch({ type: LOGOUT_REQUESTED }),
    getOptions: () => dispatch({type: GET_OPTIONS}),
    setOptions: (options) => dispatch({type: SET_OPTIONS, payload: options}),
    getUserInfo: (uid) => dispatch({ type: GET_USER_INFO, payload: uid }),
    resetUserData: () => dispatch({ type: RESET_USER_DATA })

})

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider)
