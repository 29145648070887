import { useForm } from 'react-hook-form';
import React from "react";
import {useParams} from "react-router-dom";

export const withUseFormHook = WrappedComponent => props => {
    const form = useForm();
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            form={form}
            params={params}
        />
    );
};

