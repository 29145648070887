import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {withUseFormHook} from "../../helpers/with-form-hook";
import {CLEAN_LOGIN_DATA} from "../../redux/actions/auth-actions";
import {useAuth} from "../../context/AuthContext";
import ToastMessage from "../toast";
import { toast } from "react-toastify";
import validator from "validator";

const LocalLogin = ({
    form,
}) => {

    const {
        currentUser,
        doSigninWithEmailPass,
    } = useAuth();

    const [loading, setLoading] = useState(false)

    const localSignIn = async (data) => {
        setLoading(true)
        try {
            toast.dismiss()
            await doSigninWithEmailPass(data.email, data.password)
        } catch (error) {
            setLoading(false)
            switch (error.code) {

                case 'auth/too-many-requests':
                    return toast.error(<ToastMessage text={"Too many requests."} withTryAgain={true} />,
                        {autoClose: false})
                case 'auth/wrong-password':
                    return toast.error(<ToastMessage text={"Wrong password."} withTryAgain={true} />,
                        {autoClose: false})
                case 'auth/user-not-found':
                    return toast.error(<ToastMessage text={"User not found."} withTryAgain={true} />,
                        {autoClose: false})
                default:
                    return toast.error(
                        <ToastMessage text={`An error occurred ${error.code}.`} withSupportButton={true} />,
                        {autoClose: false})
            }
        }
    }

    const { register, handleSubmit, formState: { errors } } = form;

    useEffect(()=>{
        if(currentUser){
            setLoading(false)
        }
    }, [currentUser])

    return (
        <React.Fragment>
            <form className="w-full space-y-3 mt-4">
                <div className="form-control">
                    <input
                        type="text"
                        placeholder="Enter your email"
                        className={`input input-bordered input-md ${errors.email && 'input-error'}`}
                        {...register("email", {
                            required: "The field should not be empty",
                            validate: (email) => {
                                return validator.isEmail(email) || "Enter email with correct format"
                            }
                        })}
                    />
                    {errors.email && (
                        <label className="label">
                            <span className="label-text-alt">{errors.email.message}</span>
                        </label>
                    )}
                </div>
                <div className="form-control">
                    <input
                        type="password"
                        placeholder="Enter your password"
                        className={`input input-bordered input-md ${errors.password && 'input-error'}`}
                        {...register("password", {
                            required: "The field should not be empty"
                        })}
                    />
                    {errors.password && (
                        <label className="label">
                            <span className="label-text-alt">{errors.password.message}</span>
                        </label>
                    )}
                </div>
                <button
                    className={`py-2 px-4 w-full bg-brandGray btn btn-md rounded-full btn-wide shadow-brand-button ${loading ? 'loading' : ''}`}
                    onClick={handleSubmit(localSignIn)}
                >
                    <span className="font-medium">LOGIN</span>
                </button>
            </form>
        </React.Fragment>
    )

}

LocalLogin.propTypes = {
    cleanLoginData: PropTypes.func,
    loadingLogin: PropTypes.bool,
    loginError: PropTypes.object
}

const mapStateToProps = (state) => ({
    loadingLogin: state.auth.loadingLogin,
    loginError: state.auth.loginError
})

const mapDispatchToProps = (dispatch) => ({
    cleanLoginData: () => dispatch({ type: CLEAN_LOGIN_DATA }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(LocalLogin))
