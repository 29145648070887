import {
    GET_OPTIONS_ERROR,
    GET_OPTIONS_REQUEST,
    GET_OPTIONS_SUCCESS, SET_OPTIONS,
} from "../actions/preference-actions";

import {toast} from "react-toastify";
import ToastMessage from "../../components/toast";
import React from "react";

const initialState = {
    options: [],
    loadingOptions: false,
    loadingUpdateOption: false
}

export default function preferenceReducer(state = initialState, {type, payload}) {
    switch(type) {
        case GET_OPTIONS_REQUEST: {
            return {
                ...state,
                loadingOptions: true,
            }
        }

        case GET_OPTIONS_SUCCESS:
            return {
                ...state,
                options: payload,
                loadingOptions: false,
            }

        case GET_OPTIONS_ERROR:
            toast.error(<ToastMessage text={"Cant get preference options"} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                options: null,
                loadingOptions: false,
            }

        case SET_OPTIONS:
            return {
                ...state,
                options: payload
            }

        default:
            return state
    }
}
