import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects'

import {
    ATTACH_PAYMENT_METHOD,
    ATTACH_PAYMENT_METHOD_ERROR,
    ATTACH_PAYMENT_METHOD_REQUEST, ATTACH_PAYMENT_METHOD_SUCCESS,
    DETACH_PAYMENT_METHOD,
    DETACH_PAYMENT_METHOD_ERROR,
    DETACH_PAYMENT_METHOD_REQUEST,
    DETACH_PAYMENT_METHOD_SUCCESS,
    GET_ALL_PAYMENT_METHODS,
    GET_ALL_PAYMENT_METHODS_ERROR,
    GET_ALL_PAYMENT_METHODS_REQUEST,
    GET_ALL_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHOD,
    GET_PAYMENT_METHOD_ERROR,
    GET_PAYMENT_METHOD_REQUEST,
    GET_PAYMENT_METHOD_SUCCESS,
    UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD,
    UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_ERROR,
    UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_REQUEST,
    UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_SUCCESS
} from "../actions/payment-method-actions";

import paymentApi from "../api/payment-api";
import accountApi from "../api/user-api";
import {
    SET_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_SUCCESS
} from "../actions/billing-actions";

function* getAllPaymentMethods({ payload }) {

    yield put({ type: GET_ALL_PAYMENT_METHODS_REQUEST })

    const response = yield call(accountApi.getAllPaymentMethods, payload)

    if(response.status === 200) {
        yield put({ type: GET_ALL_PAYMENT_METHODS_SUCCESS, payload: response.data })
    } else if (response.status === 404) {
        yield put({ type: GET_ALL_PAYMENT_METHODS_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_ALL_PAYMENT_METHODS_ERROR })
    }
}

function* getPaymentMethod({ payload }) {

    yield put({ type: GET_PAYMENT_METHOD_REQUEST })

    const response = yield call(paymentApi.getPaymentMethod, payload)

    if(response.status === 200) {
        yield put({ type: GET_PAYMENT_METHOD_SUCCESS, payload: response.data })
    } else {
        yield put({ type: GET_PAYMENT_METHOD_ERROR })
    }
}

function* detachPaymentMethod({ payload }) {

    yield put({ type: DETACH_PAYMENT_METHOD_REQUEST })

    const response = yield call(paymentApi.detachPaymentMethod, payload)

    if(response.status === 200) {
        yield put({ type: DETACH_PAYMENT_METHOD_SUCCESS, payload: payload })
    } else {
        yield put({ type: DETACH_PAYMENT_METHOD_ERROR })
    }
}

function* attachPaymentMethod({ payload }) {

    yield put({ type: ATTACH_PAYMENT_METHOD_REQUEST })

    const response = yield call(paymentApi.attachPaymentMethod, payload)

    if(response.status === 200) {
        yield put({ type: ATTACH_PAYMENT_METHOD_SUCCESS, payload: response.data.paymentMethod })

        if(response.data.subscription) {
            yield put({ type: SET_SUBSCRIPTION, payload: response.data.subscription })
        }

    } else {
        yield put({ type: ATTACH_PAYMENT_METHOD_ERROR })
    }
}

function* updateSubscriptionDefaultPaymentMethod({ payload }) {

    yield put({ type: UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_REQUEST })

    const response = yield call(paymentApi.updateSubscriptionDefaultPaymentMethod, payload)

    if(response.status === 200) {
        yield put({ type: UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_SUCCESS })
        yield put({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload: response.data })
    } else {
        yield put({ type: UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_ERROR })
    }
}

export default function* paymentMethodSaga() {
    yield takeLatest(GET_PAYMENT_METHOD, getPaymentMethod)
    yield takeLatest(GET_ALL_PAYMENT_METHODS, getAllPaymentMethods)
    yield takeLatest(ATTACH_PAYMENT_METHOD, attachPaymentMethod)
    yield takeLatest(DETACH_PAYMENT_METHOD, detachPaymentMethod)
    yield takeLatest(UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD, updateSubscriptionDefaultPaymentMethod)
}
