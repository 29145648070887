import React, {useEffect, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {billingPaths, testHomeworkId} from "../helpers/constants";
import {GET_USER_INFO} from "../redux/actions/user-actions";

function PrivateRoute({ children, userInfo, lock }) {

    const location = useLocation();
    const pathname = location.pathname || '/'
    const isTestHomework = pathname === testHomeworkId
    const [navigateTo, setNavigateTo] = useState('children')
    const isBillingPath = pathname !== '/' ? billingPaths.includes(pathname.slice(1)) : false

    const { currentUser } = useAuth()

    useEffect(()=>{
        if(currentUser && userInfo){
            if(lock.description !== 'init' && lock.status === true && !isBillingPath){
                setNavigateTo('/account')
            }
        }
    }, [lock, currentUser, userInfo, isBillingPath])

    if (!currentUser) {
        return <Navigate to={'/login'} state={{from: pathname}}/>
    } else if(isBillingPath || isTestHomework || navigateTo === 'children'){
        return children;
    } else {
        return <Navigate to={navigateTo} />
    }

}

PrivateRoute.propTypes = {
    lock: PropTypes.object,
    userInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
    lock: state.user.lock,
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = (dispatch) => ({
    getUserInfo: (uid) => dispatch({ type: GET_USER_INFO, payload: uid }),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
