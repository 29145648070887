import moment from "moment-timezone";
import {priceItems} from "./constants";

const isValidEmail = email =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

export function validateGiftCardCode(code) {
    const regex1 = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
    const regex2 = /^[A-Z0-9]{5}$/;
    const regex3 = /^UGC-[A-Z0-9]{6}$/;

    return regex1.test(code) || regex2.test(code) || regex3.test(code);
}

export function handleEmailValidation(email, errors) {

    const isValid = isValidEmail(email);

    const validityChanged =
        (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
}

export function getPaymentRedLine() {

    const today = moment().tz('Asia/Shanghai')
    const day = moment().tz('Asia/Shanghai')

    let tuesday;

    if(day.isoWeekday() <= 2 ) {
        tuesday = day.isoWeekday(2).set('hour', 10).set('minute', 30)
    } else {
        tuesday = day.add(1, 'weeks').isoWeekday(2).set('hour', 10).set('minute', 30);
    }

    if(today > tuesday) {
        tuesday.add(1, 'weeks')
    }

    return tuesday.utc()

}

export function isSprintDay() {

    const today = moment().tz('Asia/Shanghai')
    const monday = moment().tz('Asia/Shanghai').isoWeekday(0)
    const friday =  moment().tz('Asia/Shanghai').isoWeekday(6)

    return today.isBetween(monday,friday)
}

export function calculatedSum(price=0, weeks=0, isNew=false, currency=null) {

    let discount = isNew ? priceItems[`${currency}Discount`] : 0

    let sum = weeks * price - discount

    if( weeks === 10){
        sum = sum - price
    }

    return sum
}

export const stringToBoolean = (stringValue) => {
    switch(stringValue?.toLowerCase()?.trim()){
        case "true":
        case "yes":
        case "1":
            return true;

        case "false":
        case "no":
        case "0":
        case null:
        case undefined:
            return false;

        default:
            return JSON.parse(stringValue);
    }
}
