import React, {
    useEffect, useState,
} from 'react'
import {
    Routes,
    Route,
    useLocation, useNavigate,
} from "react-router-dom";

import {useAuth} from "../context/AuthContext";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import confApi from "../redux/api/configurator-api";
import loadable from "@loadable/component";
import {
    GET_USER_INFO,
    SET_LOCK_SPRINT
} from "../redux/actions/user-actions";

import Login from "./Login";
import Logout from "./Logout";
import Loading from "./Loading";
import {GET_SPRINT} from "../redux/actions/configurator-actions";
import {CHECK_PAYMENTS} from "../redux/actions/billing-actions";
import {stringToBoolean} from "../helpers/validation";
import Holiday from "./Holiday";
import {toast} from "react-toastify";
import ToastMessage from "./toast";
import PrivateRoute from "../routers/PrivateRoute";
import ResetPassword from "./login/ResetPassword";
import Hello from "./Hello";
import VerifyEmail from "./login/VerifyEmail";
import Menu from "./Menu";
import {usePrevious} from "../helpers/usePrevious";

const HomeworkComponent = loadable(() => import('./homework/Homework'), {
    fallback: <Loading />
});

const AccountComponent = loadable(() => import('./account'), {
    fallback: <Loading />
});

// TODO: Вернуть после интеграции с оплатами
// const BillingComponent = loadable(() => import('./billing'), {
//     fallback: <Loading />
// });

const LoginComponent = loadable(() => import('./Login'), {
    fallback: <Loading />
});

const DailySummaryComponent = loadable(() => import('./summary/DailySummary'), {
    fallback: <Loading />
});

const SprintSummaryComponent = loadable(() => import('./summary/SprintSummary'), {
    fallback: <Loading />
});

const Main = ({
                  options,
                  loadingOptions,
                  pageBackground,
                  userInfo,
                  setLock,
                  lock,
                  getSprint,
                  weekNumber,
                  currentSprint,
                  today,
                  currentTuesday,
                  currentMonday,
                  currentSunday,
                  checkPayments,
                  currentSprintLoading
}) => {

    const { currentUser } = useAuth()
    const [showHoliday, setShowHoliday] = useState(false)

    const prevCurrentSprintLoading = usePrevious(currentSprintLoading)

    const location = useLocation()
    const pathname = location.pathname

    const navigate = useNavigate();

    useEffect(()=>{
        if(pathname.startsWith('/homework')){
            document.body.style.backgroundColor = pageBackground.background;
        } else {
            document.body.style.backgroundColor = '#fff7ef';
        }
    }, [pageBackground, pathname])

    useEffect(()=>{
        const checkPermissions = () => {

            let dateCheck = userInfo.balance.lastPaymentDate === null || moment(userInfo.balance.lastPaymentDate).tz('Asia/Shanghai') < currentTuesday.utc()

            const balanceCheck = userInfo.balance.weeks > 0
            const profileStatusCheck = userInfo.profile.status === 'active'
            const roleCheck = userInfo.isStudent
            let description = 'init'

            if(!balanceCheck) {
                description = 'balance'
            } else if(!roleCheck) {
                description = 'role'
            } else if(!profileStatusCheck) {
                description = 'status'
            }

            // TODO: Вернуть после интеграции с платежами
            //
            // if(!balanceCheck) {
            //     description = 'balance'
            // } else if(!dateCheck) {
            //     description = 'paymentDate'
            // } else if(!roleCheck) {
            //     description = 'role'
            // } else if(!profileStatusCheck) {
            //     description = 'status'
            // }

            setLock({
                status: !(dateCheck && balanceCheck && profileStatusCheck && roleCheck),
                description: description
            })
        }

        // TODO: Необходимо переделать с учетом того что у нас может быть более чем один домент и возможно не быть автоматизированных оплат
        // if(userInfo && pathname !== '/verify-email') {
        //     checkPayments(userInfo.uid)
        // }

        if(prevCurrentSprintLoading === true && currentSprintLoading === false){
            if(userInfo && currentSprint){
                if(Object.keys(currentSprint).length > 0){
                    if(currentSprint.enrolled){
                        // Студенты зачислены на спринт. Проверяем студента в списках
                        confApi.findUserInCurrentSprint(userInfo.uid).then((response) => {
                            if(response.status === 200){
                                // Студент в списках на зачисление есть/нет.
                                setLock({
                                    status: !response.data.length > 0,
                                    description: 'sprint'
                                })
                            } else {
                                toast.error(
                                    <ToastMessage text={`An error occurred ${response.status}.`} withSupportButton={true} />,
                                    {autoClose: false}
                                )
                            }
                        })
                    } else {
                        // Студенты еще не зачислены на спринт
                        if(today.isBetween(currentMonday, currentTuesday)){
                            // Сейчас время между понедельником и вторником 10:30. Делаем простую проверку
                            checkPermissions()
                        } else if(today.isBetween(currentTuesday, currentSunday)) {
                            if(userInfo.balance.weeks < 1){
                                // Сейчас время между вторником 10:30 и воскресеньем. У студента нет доступа к курсу
                                setLock({
                                    status: true,
                                    description: 'sprint'
                                })
                            } else {
                                // Сейчас время между вторником 10:30 и воскресеньем. У студента есть доступ к курсу
                                setLock({
                                    status: false,
                                    description: 'sprint'
                                })
                            }
                        } else {
                            // Сейчас воскресенье
                            checkPermissions()
                        }
                    }
                } else {
                    setLock({
                        status: true,
                        description: 'empty-sprint'
                    })
                    navigate('/account')
                }

            } else if(userInfo && !currentSprint) {
                setLock({
                    status: true,
                    description: 'sprint'
                })
            }
        }



    }, [
        userInfo,
        currentSprint,
        currentSprintLoading,
        checkPayments,
        currentMonday,
        currentSunday,
        currentTuesday,
        navigate,
        pathname,
        prevCurrentSprintLoading,
        setLock,
        today
    ])

    useEffect(()=> {
        if(options && options.length > 0) {

            options.forEach((option)=>{
                if(stringToBoolean(option.value)){
                    setShowHoliday(false)
                    getSprint(weekNumber)
                } else {
                    setShowHoliday(true)
                }
            })

        }
    }, [options, showHoliday, getSprint, weekNumber])

    useEffect(()=>{
        document.getElementById('body').style = `background:${pageBackground.background};`
    }, [pageBackground])

    return (
       <>

               {(currentUser && loadingOptions) || currentSprintLoading ? (
                   <Loading />
               ) : (
                   <>
                       <div className={"flex flex-col min-h-screen w-full xs:max-w-laptop"} >
                           {<Routers showHolyday={showHoliday} currentSprint={currentSprint} />}
                       </div>
                       {!showHoliday && <Menu/>}
                   </>
               )}
       </>
   )
}

Main.propTypes = {
    pageBackground: PropTypes.object,
    checking: PropTypes.bool,
    setLock: PropTypes.func,
    checkPayments: PropTypes.func,
    currentSprint: PropTypes.object,
    currentSprintLoading: PropTypes.bool,
    getOptions: PropTypes.func,
    options: PropTypes.array,
    loadingOptions: PropTypes.bool,
    lock: PropTypes.object
}

const mapStateToProps = (state) => ({
    pageBackground: state.common.pageBackground,
    currentSprint: state.config.currentSprint,
    currentSprintLoading: state.config.currentSprintLoading,
    today: state.common.today,
    currentMonday: state.common.currentMonday,
    currentSunday: state.common.currentSunday,
    currentTuesday: state.common.currentTuesday,
    weekNumber: state.common.weekNumber,
    userInfo: state.user.userInfo,
    options: state.preference.options,
    loadingOptions: state.preference.loadingOptions,
    lock: state.user.lock
})

const mapDispatchToProps = (dispatch) => ({
    getUserInfo: (uid) => dispatch({ type: GET_USER_INFO, payload: uid }),
    getSprint: (weekNumber) => dispatch({ type: GET_SPRINT, payload: weekNumber }),
    setLock: (status) => dispatch({ type: SET_LOCK_SPRINT, payload: status }),
    checkPayments: (userUid) => dispatch({ type: CHECK_PAYMENTS, payload: userUid }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)

const Routers = ({ showHoliday, currentSprint }) => {

    if (showHoliday){
        return <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="*" element={<Holiday />} />
        </Routes>
    } else {

        return <Routes>
            {['/', '/sprint'].map((path, index) => (
                <Route path={path} element={<PrivateRoute><SprintSummaryComponent /></PrivateRoute>} key={index} />

            ))}
            <Route path={'/day'} element={<PrivateRoute><DailySummaryComponent /></PrivateRoute>} />
            <Route exact path="/homework/:homeworkId" element={<PrivateRoute><HomeworkComponent /></PrivateRoute>} />
            <Route exact path="/reset/:uid/:token" element={<ResetPassword />} />
            {/*<Route path="/talks" element={<PrivateRoute><EisTalks /></PrivateRoute>} />*/}
            {/*<Route path="/end" element={<PrivateRoute><TalksEnd /></PrivateRoute>} />*/}
            <Route path="*" element={<PrivateRoute><Hello /></PrivateRoute>} />
            <Route exact path="/account" element={<PrivateRoute><AccountComponent sprint={currentSprint} /></PrivateRoute>} />

            {/* TODO: Вернуть после интеграции с оплатами */}
            {/*<Route exact path="/billing" element={<PrivateRoute><BillingComponent /></PrivateRoute>} />*/}
            <Route exact path="/login" element={<LoginComponent />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path={"/verify-email"} element={<VerifyEmail />} />
        </Routes>
    }
}


