import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects'

import {
    GET_BALANCE_HISTORY,
    GET_BALANCE_HISTORY_REQUEST,
    GET_BALANCE_HISTORY_SUCCESS,
    GET_BALANCE_HISTORY_ERROR,
    GET_SUBSCRIPTION_REQUEST,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR,
    GET_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_REQUEST,
    UPDATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_ERROR,
    DELETE_SUBSCRIPTION_REQUEST,
    DELETE_SUBSCRIPTION_SUCCESS,
    DELETE_SUBSCRIPTION_ERROR,
    UPDATE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION_SCHEDULE_REQUEST,
    DELETE_SUBSCRIPTION_SCHEDULE_SUCCESS,
    DELETE_SUBSCRIPTION_SCHEDULE_ERROR,
    DELETE_SUBSCRIPTION_SCHEDULE,
} from "../actions/billing-actions";

import {SET_BALANCE_HISTORY_PAGINATION_TOTAL_RECORDS} from "../actions/pagination-actions";

import userApi from '../api/user-api'
import paymentApi from "../api/payment-api";

function* getBalanceHistory({ payload }) {

    yield put({ type: GET_BALANCE_HISTORY_REQUEST })

    const response = yield call(userApi.getUserBalanceHistory, payload)

    if(response.status === 200) {
        yield put({ type: GET_BALANCE_HISTORY_SUCCESS, payload: response.data.results })
        yield put({ type: SET_BALANCE_HISTORY_PAGINATION_TOTAL_RECORDS, payload: response.data.count })
    } else if (response.status === 404) {
        yield put({ type: GET_BALANCE_HISTORY_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_BALANCE_HISTORY_ERROR })
    }
}

function* getSubscription({ payload }) {

    yield put({ type: GET_SUBSCRIPTION_REQUEST })

    const response = yield call(userApi.getUserSubscription, payload)

    if(response.status === 200) {
        yield put({ type: GET_SUBSCRIPTION_SUCCESS, payload: response.data })
    } else if (response.status === 404) {
        yield put({ type: GET_SUBSCRIPTION_SUCCESS, payload: null })
    } else {
        yield put({ type: GET_SUBSCRIPTION_ERROR })
    }

}

function* updateSubscription({ payload }) {

    yield put({ type: UPDATE_SUBSCRIPTION_REQUEST })

    const response = yield call(paymentApi.updateStripeSubscription, payload)

    if(response.status === 200) {
        yield put({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload: response.data })
    } else {
        yield put({ type: UPDATE_SUBSCRIPTION_ERROR })
    }

}

function* deleteSubscription({ payload }) {

    yield put({ type: DELETE_SUBSCRIPTION_REQUEST })

    const response = yield call(paymentApi.deleteStripeSubscription, payload)

    if(response.status === 200) {
        yield put({ type: DELETE_SUBSCRIPTION_SUCCESS })
    } else {
        yield put({ type: DELETE_SUBSCRIPTION_ERROR })
    }

}

function* deleteSubscriptionSchedule({ payload }) {

    yield put({ type: DELETE_SUBSCRIPTION_SCHEDULE_REQUEST })

    const response = yield call(paymentApi.deleteStripeSubscriptionSchedule, payload)

    if(response.status === 200) {
        yield put({ type: DELETE_SUBSCRIPTION_SCHEDULE_SUCCESS })
    } else {
        yield put({ type: DELETE_SUBSCRIPTION_SCHEDULE_ERROR })
    }

}

export default function* billingSaga() {
    yield takeLatest(GET_BALANCE_HISTORY, getBalanceHistory)
    yield takeLatest(GET_SUBSCRIPTION, getSubscription)
    yield takeLatest(UPDATE_SUBSCRIPTION, updateSubscription)
    yield takeLatest(DELETE_SUBSCRIPTION, deleteSubscription)
    yield takeLatest(DELETE_SUBSCRIPTION_SCHEDULE, deleteSubscriptionSchedule)
}
