export const SET_LOADING = "SET_LOADING"
export const SET_LOADING_REQUESTED="SET_LOADING_REQUESTED"

export const GET_HOMEWORK = "GET_HOMEWORK"

export const GET_SPRINT = "GET_SPRINT"
export const GET_SPRINT_REQUEST = "GET_SPRINT_REQUEST"
export const GET_SPRINT_SUCCESS = "GET_SPRINT_SUCCESS"
export const GET_SPRINT_ERROR = "GET_SPRINT_ERROR"

export const CLEAN_HOMEWORK_DATA = "CLEAN_HOMEWORK_DATA"

export const GET_STUDENT_ANSWERS_REQUEST = "GET_STUDENT_ANSWERS_REQUEST"
export const GET_STUDENT_ANSWERS_SUCCESS = "GET_STUDENT_ANSWERS_SUCCESS"
export const GET_STUDENT_ANSWERS_ERROR = "GET_STUDENT_ANSWERS_ERROR"

export const GET_HOMEWORK_CONFIG_REQUEST = "GET_HOMEWORK_CONFIG_REQUEST"
export const GET_HOMEWORK_CONFIG_SUCCESS = "GET_HOMEWORK_CONFIG_SUCCESS"
export const GET_HOMEWORK_CONFIG_ERROR = "GET_HOMEWORK_CONFIG_ERROR"

export const CREATE_STUDENT_ANSWERS = "CREATE_STUDENT_ANSWER"
export const CREATE_STUDENT_ANSWERS_REQUEST = "CREATE_STUDENT_ANSWER_REQUEST"
export const CREATE_STUDENT_ANSWERS_SUCCESS = "CREATE_STUDENT_ANSWER_SUCCESS"
export const CREATE_STUDENT_ANSWERS_ERROR = "CREATE_STUDENT_ANSWER_ERROR"

export const SET_STUDENT_ANSWERS = "SET_STUDENT_ANSWERS"
export const SET_EMPTY_STUDENT_ANSWERS = "SET_EMPTY_STUDENT_ANSWERS"

export const SET_HOMEWORK_ID = "SET_HOMEWORK_ID"
export const SET_EMPTY_HOMEWORK_ID = "SET_EMPTY_HOMEWORK_ID"
export const SET_HOMEWORK_ID_REQUESTED = "SET_HOMEWORK_ID_REQUESTED"
export const GET_STUDENT_ANSWERS_REQUESTED = "GET_STUDENT_ANSWERS_REQUESTED"

export const SET_HOMEWORK_CONFIG = "SET_HOMEWORK_CONFIG"
export const SET_EMPTY_HOMEWORK_CONFIG = "SET_EMPTY_HOMEWORK_CONFIG"
export const SET_EMPTY_HOMEWORK_CONFIG_REQUESTED = "SET_EMPTY_HOMEWORK_CONFIG_REQUESTED"
export const GET_CONFIG_BY_HOMEWORK = "GET_CONFIG_BY_HOMEWORK"
export const GET_CONFIG_BY_HOMEWORK_REQUESTED = "GET_CONFIG_BY_HOMEWORK_REQUESTED"
export const SET_HOMEWORK_CONFIG_ASSIGNMENT = "SET_HOMEWORK_CONFIG_ASSIGNMENT"
export const SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED = "SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED"

export const GET_DAILY_SUMMARY = "GET_DAILY_SUMMARY"
export const GET_DAILY_SUMMARY_REQUEST = "GET_DAILY_SUMMARY_REQUEST"
export const GET_DAILY_SUMMARY_SUCCESS = "GET_DAILY_SUMMARY_SUCCESS"
export const GET_DAILY_SUMMARY_ERROR = "GET_DAILY_SUMMARY_ERROR"

export const GET_SPRINT_SUMMARY = "GET_SPRINT_SUMMARY"
export const GET_SPRINT_SUMMARY_REQUEST = "GET_SPRINT_SUMMARY_REQUEST"
export const GET_SPRINT_SUMMARY_SUCCESS = "GET_SPRINT_SUMMARY_SUCCESS"
export const GET_SPRINT_SUMMARY_ERROR = "GET_SPRINT_SUMMARY_ERROR"

export const SET_DAILY_SUMMARY = "SET_DAILY_SUMMARY"
export const RESET_DAILY_SUMMARY = "RESET_DAILY_SUMMARY"

export const CLEAR_HOMEWORK_CONFIG_FORM = "CLEAR_HOMEWORK_CONFIG_FORM"

export const SET_FTF = "SET_FTF"
export const SET_FTF_PAIR = "SET_FTF_PAIR"

export const SET_EMPTY_FTF = "SET_EMPTY_FTF"
export const SET_EMPTY_FTF_PAIR = "SET_EMPTY_FTF_PAIR"

export const SET_EMPTY_ALL = "SET_EMPTY_ALL"
export const GET_FTF_PAIR_BY_DATES_REQUESTED = "GET_FTF_PAIR_BY_DATES_REQUESTED"
