import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from './redux/store'
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react'
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ToastProvider from "./context/ToastContext";

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ToastProvider>
                    <App />
                </ToastProvider>
            </PersistGate>
        </Provider>
    </React.Fragment>,
    document.getElementById("root")
);

serviceWorkerRegistration.register();

reportWebVitals();
